<template>
	<div class="course-arrage">
      <h2 class="tit">课程<span>安排</span>（部分）</h2>
      <ul class="course-tab">
				<li @click="cur=0" :class="{active:cur==0}" >
					<div class="tab-name">低段</div>
					<div class="tab-tip">适合一、二年级</div>
				</li>
				<li @click="cur=1" :class="{active:cur==1}">
					<div class="tab-name">中高段</div>
					<div class="tab-tip">适合三、四、五年级</div>
				</li>
			</ul>
			<div class="tab-content">
				<div class="list-header" v-if="flag===1">
					<div class="schedule">课时</div>
					<div class="classic">选自</div>
					<div class="knowledge">课程</div>
				</div>
        <div class="list-header" v-else>
					<div class="schedule">课序</div>
					<div class="classic">题目</div>
					<div class="knowledge">原文</div>
				</div>
				<transition name="slide-fade">
					<div v-show="cur==0">
						<div class="list-body">
							<div class="list-box" v-for="(item,index) in primaryCourse" :key="index">
								<div class="schedule" >
									<p v-for="(it,inx) in item.week" :key="inx">{{it}}</p>
								</div>
								<div class="classic">
									<p>{{item.classic}}</p>
								</div>
								<div class="knowledge">
									<p v-for="(it,inx) in item.knowledge.slice(0, 3)" :key="inx">{{it}}</p>
									<p>......</p>
								</div>
							</div>  
						</div>
					</div>
				</transition>
				<transition name="slide-fade">
					<div v-show="cur==1">
						<div class="list-body">
							<div class="list-box" v-for="(item,index) in midCourse" :key="index">
								<div class="schedule" >
									<p v-for="(it,inx) in item.week" :key="inx">{{it}}</p>
								</div>
								<div class="classic">
									<p>{{item.classic}}</p>
								</div>
								<div class="knowledge">
									<p v-for="(it,inx) in item.knowledge.slice(0, 3)" :key="inx">{{it}}</p>
									<p>......</p>
								</div>
							</div>                
						</div>
					</div>	
				</transition>
			</div>
    </div>  
</template>
<script>
export default {
    props: {
    midCourse: {
      // 内容
      type: Array,
      required: true
    },
    primaryCourse:{
      type: Array,
      required: true
    },
    flag:{
      type:Number
    }
  },
  data(){
    return{
      cur:0
    }
  }
}
</script>
<style lang="scss" scoped>
	.course-arrage{
    display: flex;
    align-items: center;
    flex-direction: column;
    &.cur-green{
          h2{
            color: #444144;
            font-size: .587rem;
            margin: .573rem .48rem;
            span{
                color: #1DBA5C;
            }
        }
        .course-tab li{
           color: #1DBA5C;
            border:.027rem solid #1DBA5C;
            &:hover{
              background-color: #EBFFF3;
            }
            &.active{
              background: #1DBA5C;
            }
        }
        .tab-content {
          & .list-header div{
            background: #1DBA5C;
            }
          & .list-body .list-box{
            color: #333!important;
            &:nth-child(2n+2) div{
              background: #86E3AC;
            }
            &:nth-child(2n+1) div{background: #EBFFF3}
            }
        }
    }
    &.cur-blue{
       h2{
            color: #444144;
            font-size: .587rem;
            margin: .573rem .48rem;
            span{
                color: #56c1ff;
            }
        }
        .course-tab li{
           color: #56c1ff;
            border:.027rem solid #56c1ff;
            &:hover{
              background-color: #ecf9ff;
            }
            &.active{
              background:#56c1ff ;
            }
        }
        .tab-content {
          & .list-header div{
            background: #56c1ff;
            }
          & .list-body .list-box{
            color: #333!important;
            &:nth-child(2n+2) div{
              background: #d5f2ff;
            }
            &:nth-child(2n+1) div{background: #ecf9ff}
            }
        }
    }

    .course-tab{
			list-style: none;
			display: flex;
			width: 9.04rem;
			justify-content: space-between;
			margin: .3rem 0 .4rem;
			padding: 0;
			li{
				width:4.187rem;
				height:1.067rem;
				color: #FF8F63;
				border-radius: .133rem;
				box-sizing: border-box;
				border:.027rem solid #FF8F63;
				cursor: pointer;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				&:hover{
					background-color: #FFEDE6;
				}
				.tab-name{
					font-size: .38rem;
					font-weight: bold;
				}
				.tab-tip{
					font-size: .2rem;
				}
				&.active{
					border: 0;
					background-color: #FF8F63;
					color: #fff;
				}
			}
    }
    .tab-content{
			width: 9.04rem;
			color: #fff;
			.list-header{
				display: flex;
				div{
					background-color: #FFB463;
					font-size: .267rem;
					font-weight: bold;
					min-height: .8rem;
					display: flex;
					align-items: center;
					justify-content: center;
					margin-bottom: .107rem;
					&:not(:last-child){
						margin-right: .107rem;
					}
				}
				.schedule{
					width:2.453rem;
					height:.747rem;
					border-radius:.16rem 0rem 0rem 0rem;
				}
				.classic{
					width:3.307rem;
					height:.747rem;
					justify-content: center;
					word-break:break-all;
					p{
						width: 3.387rem;
						padding: 0rem .347rem;
						box-sizing: border-box;
					}
				}
				.knowledge{
					width:3.147rem;
					height:.747rem;
					border-radius:0rem .16rem 0rem 0rem;
					p{
						width: 100%;
						padding: 0rem .347rem;
						box-sizing: border-box;
					}
				}
			}
			.show-all{
				height: .224rem;
			}
			.show-less{
				height: 6.3rem;
			}
			.list-body{
				width: 100%;
				.list-box{
					display: flex;
					.schedule{
						width:2.453rem;
						height:4rem;
					}
					.classic{
						width:3.307rem;
						height:4rem;
					}
					.knowledge{
						width:3.147rem;
						height:4rem;
					}
					&:last-child{
						.schedule{
							border-radius:0rem 0rem 0rem .16rem;
						}
						.knowledge{
							border-radius:0rem 0rem .16rem 0rem;
						}
					}
					&:nth-child(2n+1){
						div{
							background-color: #FFBB3B;
						}
					}
					div{
						background-color: #FFBB3B;
						display: flex;
						align-items: center;
						justify-content: center;
						flex-direction: column;
						font-size: .38rem;
						line-height: .5rem;
						box-sizing: border-box;
						padding: .16rem .347rem;
						&:not(:last-child){
							margin-right: .107rem;
						}
					}
					&:not(:last-child){
						div{
							margin-bottom: .107rem;
						}
					}
				}
      }
    }
  }
</style>
